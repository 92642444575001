
import { defineComponent, onMounted, ref } from "vue";
import { requiredField } from "@/util/form-rules";
import useQuasar from 'quasar/src/composables/use-quasar.js';;
import Service from "@/services/auth/AuthService";
import store from "@/store";
import router from "@/router";

interface Form {
  password: string;
}

const baseForm: Form = {
  password: "",
};

import { useRoute } from "vue-router";
export default defineComponent({
  setup() {
    const $q = useQuasar();
    const password = ref<string>("");
    const loading = ref<boolean>(false);
    const form = ref<Form>(baseForm);
    const route = useRoute();

    const submit = (): void => {
      const token: string = route.params.token.toString();
      const user: string = route.params.user.toString();
      loading.value = true;
      Service.resetarSenha(token, user, form.value)
        .then(() => {
          showNotify({
            type: "positive",
            message: "Senha alterada com sucesso!",
          });
          router.push("/");
        })
        .catch((err) => {
          console.log(err);
          showNotify({
            type: "negative",
            message: "Erro ao alterar senha",
          });
        })
        .finally(() => (loading.value = false));
    };

    const showNotify = (opts: any): void => {
      $q.notify(opts);
    };

    onMounted(() => {
      if (store.getters.isLoggedIn) {
        router.push("/");
      }
    });

    return {
      requiredField,
      password,
      loading,
      showNotify,
      submit,
      form,
    };
  },
});
